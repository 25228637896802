import React from 'react'

import RaccAlertMessage from 'Shared/racc_alert_message'

type successMessageProps = {
  message: string | string[]
}
const SuccessMessage = ({ message }: successMessageProps): JSX.Element => (
  <RaccAlertMessage type='success'>{message}</RaccAlertMessage>
)

export default SuccessMessage
