import React from 'react'
import _ from 'lodash'

import RaccAlertMessage from 'Shared/racc_alert_message'

type errorMessagesProps = {
  messages: string | string[]
}

const ErrorMessages = ({ messages }: errorMessagesProps): JSX.Element => {
  const renderMessages =
    typeof messages === 'object' ? (
      _.map(messages, (msg: string): JSX.Element => {
        return <div key={_.uniqueId()}>{msg}</div>
      })
    ) : (
      <div>{messages}</div>
    )

  return <RaccAlertMessage type='error'>{renderMessages}</RaccAlertMessage>
}

export default ErrorMessages
