import React, { ReactNode } from 'react'
import alertTypes from 'Shared/racc_alert_message/constants/alert_message_types'

type raccAlertMessageProps = {
  type: string
  children: ReactNode
}
const RaccAlertMessage = ({
  type,
  children,
}: raccAlertMessageProps): JSX.Element => {
  let alertClass: string
  switch (type) {
    case alertTypes.SUCCESS:
      alertClass = 'c-alert--success'
      break
    case alertTypes.NOTICE:
      alertClass = 'c-alert--notice'
      break
    default:
      alertClass = 'c-alert--error'
  }

  return <div className={'c-alert ' + alertClass}>{children}</div>
}

export default RaccAlertMessage
